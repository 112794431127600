<template>
  <div
    id="appleid-signin"
    data-color="white"
    data-border="true"
    data-type="sign in"
    class="signin-button"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      clientId: "com.klemix.siwa.service",
      scope: "email",
      redirectURI: "https://klemix.com/siwa",
      state: "some_random_csrf_token",
      widgetId: 0
    };
  },
  methods: {
    render() {
      window.AppleID.auth.init({
        clientId: this.clientId,
        scope: this.scope,
        redirectURI: this.redirectURI,
        state: this.state,
        usePopup: true
      });
    },
    signedIn(data) {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    signInFailed(error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  mounted() {
    // render the recaptcha widget when the component is mounted
    if (typeof window.AppleID === "undefined") {
      var script = document.createElement("script");
      script.src =
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
      script.onload = this.render;
      document.head.appendChild(script);
    } else this.render();

    document.addEventListener("AppleIDSignInOnSuccess", this.signedIn);
    document.addEventListener("AppleIDSignInOnFailure", this.signInFailed);
  },
  beforeDestroy() {
    document.removeEventListener("AppleIDSignInOnSuccess", this.signedIn);
    document.removeEventListener("AppleIDSignInOnFailure", this.signInFailed);
  }
};
</script>

<style lang="sass">
.signin-button
    height: 40px
.signin-button > div > div > svg
    width: 100%
    height: 100%
    color: red
.signin-button:hover
    cursor: pointer
.signin-button > div
    outline: none
</style>
