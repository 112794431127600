<template>
  <Public>
    <div role="main" class="container">
      <div class="row justify-content-md-center">
        <h1 class="col-6" style="text-align: center">Sign in</h1>
      </div>
      <div class="row justify-content-md-center">
        <b-card class="col-6">
          <b-card-text>
            <b-alert
              :show="showError"
              variant="danger"
              dismissible
              @dismissed="showError = false"
              >Wrong!</b-alert
            >
            <b-form @submit="onSubmit" v-if="show" novalidate>
              <b-form-group
                id="input-group-1"
                label="Username"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.username"
                  autocomplete="username"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Password"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.password"
                  autocomplete="current-password"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button class="btn-block" type="submit" variant="primary"
                >Sign in</b-button
              >
            </b-form>
            <b-form @submit="onSubmit" v-if="showTfa" novalidate>
              <b-form-group
                id="input-group-1"
                label="TFA Code"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.otpSecret"
                  placeholder="Code"
                  autocomplete="one-time-code"
                  required
                ></b-form-input>
              </b-form-group>

              <b-button class="btn-block" type="submit" variant="primary"
                >Sign in</b-button
              >
            </b-form>
            <center v-if="showSiwa">- or -</center>
            <AppleSignIn v-if="showSiwa"></AppleSignIn>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </Public>
</template>

<script>
import Public from "@/components/Public.vue";
import AppleSignIn from "@/components/AppleSignIn.vue";

export default {
  name: "Login",
  components: {
    Public,
    AppleSignIn
  },
  mounted() {
    if (this.$auth.check()) this.$router.replace({ name: "home" });
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        otpSecret: ""
      },
      show: true,
      showTfa: false,
      showError: false,
      showSiwa: false
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();

      var _this = this;
      var form = {};
      form.username = this.form.username;
      form.password = this.form.password;

      if (this.form.otpSecret && this.form.otpSecret.length > 0)
        form.otpSecret = this.form.otpSecret;

      this.$auth
        .login({
          data: form
        })
        .then(response => {
          if (
            response.data.needsTfa !== undefined &&
            response.data.needsTfa == true
          ) {
            _this.show = false;
            _this.showTfa = true;
          }
        })
        .catch(() => {
          _this.show = true;
          _this.showTfa = false;
          _this.showError = true;
        });
    }
  }
};
</script>
